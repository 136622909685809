




import React, { useState, useEffect } from "react";
import { Input, Table, Modal, Button, message, Dropdown, Select, Form } from "antd";
import { Icon } from "@iconify/react";
import CustomerSummary from "./CustomerSummary";
import { ticketllm, tickettemplate } from "../../../api/accountAction";
import { ConnectContactLens } from "aws-sdk";
import { useDispatch, useSelector } from "react-redux";



function TicketSys(props) {

  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Search } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const { AcceptCoversationID } = useSelector((state) => state.chat);
  console.log(AcceptCoversationID, "conversation_id")
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [loaderstatus1, setLoaderStatus1] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [ShowTicketDetails, setShowTicketDetails] = useState(false);
  const [LoaderSendEmail, setLoaderSendEmail] = useState(false);
  const [LoaderTicketSend, setLoaderTicketSend] = useState(false);
  const [LoaderSendMessage, setLoaderSendMessage] = useState(false);
  const [isAddIcon, setIsAddIcon] = useState("");
  const [iconloader, setIconLoader] = useState(false);
  const [loaderTemplate, setLoaderTemplate] = useState(false);
  
  const itemsToDisplay = Array.isArray(checkedItems) ? checkedItems : [];

  const handleCancelAdd = () => {
    setIsAddIcon(false);
  };

  const Addicon = () => {
    setIsAddIcon(true);
  };
  const handleViewDetails = (record) => {
    setSelectedRow(record);
    setShowTicketDetails(true);
  };
  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSource.filter(
      (item) =>
        item.ticketId.toLowerCase().includes(value.toLowerCase()) ||
        item.description.toLowerCase().includes(value.toLowerCase())||
        item.ticketTitle.toLowerCase().includes(value.toLowerCase())
    );
    setDataSource(filteredData);
  };


  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'ticketId',
      key: 'ticketId',
    },
    {
      title: 'Ticket Title',
      dataIndex: 'ticketTitle',
      key: 'ticketTitle',
    },


    {
      title: 'Priority',
      dataIndex: 'Priority',
      key: 'Priority',
    },

    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <a onClick={() => handleViewDetails(record)}>
          <Icon icon="ph:eye" style={{ color: "white" }} />
        </a>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      ticketId: "341236",
      ticketTitle: "Payment Processing Issue",
      Priority: "High",
      Status: "In-Progress",
      statusHistory: [
        { status: "Opened", date: "2024-06-01" },
        {
          status: "Assigned To-Arjun",
          date: "2024-06-02",
          assignedTo: "Arjun",
        },
        { status: "In-Progress", date: "2024-06-03" },
      ],
      description:
        "This ticket was opened on June 1, 2024, due to a payment processing issue. It was assigned to Arjun on June 2, 2024, and is currently in-progress as of June 3, 2024. The steps taken so far are: Opened on June 1, 2024; Assigned to Kowsalya on June 2, 2024; In-Progress on June 3, 2024.",
    },

    {
      key: "2",
      ticketId: "341247",
      ticketTitle: "Website Login Problem",
      Priority: "Medium",
      Status: "Completed",
      statusHistory: [
        { status: "Opened", date: "2024-05-30" },
        {
          status: "Assigned To-Ram",
          date: "2024-05-31",
          assignedTo: "Ram",
        },
        { status: "In-Progress", date: "2024-06-01" },
        { status: "Completed", date: "2024-06-02" },
      ],
      description:
        "This ticket was opened on May 30, 2024, due to a website login problem. It was assigned to Ram on May 31, 2024, progressed to in-progress on June 1, 2024, and was completed on June 2, 2024. The steps taken were: Opened on May 30, 2024; Assigned to Arjun on May 31, 2024; In-Progress on June 1, 2024; Completed on June 2, 2024.",
    },

    {
      key: "3",
      ticketId: "341258",
      ticketTitle: "Product Not Received",
      Priority: "Low",
      Status: "Completed",
      statusHistory: [
        { status: "Opened", date: "2024-06-01" },
        {
          status: "Assigned TO-Sanjana",
          date: "2024-06-02",
          assignedTo: "Sanjana",
        },
        { status: "Completed", date: "2024-06-03" },
      ],
      description:
        "This ticket was opened on June 1, 2024, due to a product not being received. It was assigned to Sanjana on June 2, 2024, and was completed on June 3, 2024. The steps taken were: Opened on June 1, 2024; Assigned to Sanjana on June 2, 2024; Completed on June 3, 2024.",
    },

    {
      key: "4",
      ticketId: "341269",
      ticketTitle: "Billing Inquiry",
      Priority: "Medium",
      Status: "In-Progress",
      statusHistory: [
        { status: "Opened", date: "2024-06-01" },
        {
          status: "Assigned To-Vikram",
          date: "2024-06-02",
          assignedTo: "Vikram",
        },
        { status: "In-Progress", date: "2024-06-03" },
      ],
      description:
        "This ticket was opened on June 1, 2024, due to a billing inquiry. It was assigned to Vikram on June 2, 2024, and is currently in-progress as of June 3, 2024. The steps taken were: Opened on June 1, 2024; Assigned to Vikram on June 2, 2024; In-Progress on June 3, 2024.",
    },

    {
      key: "5",
      ticketId: "341271",
      ticketTitle: "Website Bug Report",
      Priority: "High",
      Status: "Completed",
      statusHistory: [
        { status: "Opened", date: "2024-05-28" },
        {
          status: "Assigned To-Hasini",
          date: "2024-05-29",
          assignedTo: "Hasini",
        },
        { status: "In-Progress", date: "2024-05-30" },
        { status: "Completed", date: "2024-05-31" },
      ],
      description:
        "This ticket was opened on May 28, 2024, to report a bug on the website. It was assigned to Hasini on May 29, 2024, progressed to in-progress on May 30, 2024, and was completed on May 31, 2024. The steps taken were: Opened on May 28, 2024; Assigned to Kowsalya on May 29, 2024; In-Progress on May 30, 2024; Completed on May 31, 2024.",
    },
  ];

  const renderPriorityIcon = (priority) => {
    switch (priority) {
      case "High":
        return (
          <Icon
            icon="gg:arrow-up-o"
            width="1.6em"
            height="1.6em"
            style={{ color: "red" }}
          />
        );
      case "Medium":
        return (
          <Icon
            icon="gg:arrow-up-o"
            width="1.6em"
            height="1.6em"
            style={{ color: "#ffc107" }}
          />
        );
      case "Low":
        return (
          <Icon
            icon="gg:arrow-down-o"
            width="1.6em"
            height="1.6em"
            style={{ color: "green" }}
          />
        );
      default:
        return null;
    }
  };

 

  const handleTextAreaChange = (event) => {
    const newItems = event.target.value.split('\n').filter(item => item.trim() !== '');
    setCheckedItems(newItems);

  };


  const onClick = ({ key }) => {
    const item = items.find((item) => item.key === key);
    if (item) {

      if (item.key === '1') {
        setIsModalVisible(true);
      }
      if (item.key === '2') {
        setIsModalVisible2(true);
      }
    }
  };

  // const handleOk = () => {
  //   console.log(`Ticket ID: ${ticketId}, Ticket Name: ${ticketName}`);
  //   setIsModalVisible(false);
  // };


  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisible2(false);
    setShowTicketDetails(false);
    console.log("cancell")
    form.resetFields();

  };
  const handleCancelTicket = () => {
    setShowTicketDetails(false);
  };

  const items = [
    {
      label: 'Create Ticket',
      key: '1',
    },
    {
      label: 'Search Ticket',
      key: '2',
    },

  ];

  const handleSubmitbutton = async () => {
    form.validateFields().then(values => {
      console.log('Form values:', values);
      setLoaderStatus1(true);
      setTimeout(() => {
        setIsSuccessModalVisible(true);
        setIsModalVisible(false);
        setLoaderStatus1(false);
        // setShowTicketDetails(true);
        form.resetFields();
      }, 2000);
    }).catch(errorInfo => {
      console.log('Validation failed:', errorInfo);
    });
  };


  const handleSuccessClose = () => {
    setIsSuccessModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      setTicketId(generateTicketId());
      form.setFieldsValue({ ticketId: generateTicketId() }); 
    }
  }, [isModalVisible, form]);
  
  useEffect(() => {
    setDataSource(data); // Initialize dataSource with the full list of tickets
  }, []);

  const handleSendEmail = () => {
    setLoaderSendEmail(true);
    setTimeout(() => {
      setIsSuccessModalVisible(false);
      message.info("Email sent successfully");
      setLoaderSendEmail(false);
    }, 2000);
  };

  const handleSendMessage = () => {
    setLoaderSendMessage(true);
    setTimeout(() => {
      message.info("Message sent successfully");
      setIsSuccessModalVisible(false);
      setLoaderSendMessage(false);
    }, 2000);
  };

  // const handleSearchEmail = () => {
  //   setLoaderSendEmail(true);
  //   setTimeout(() => {
  //     setShowTicketDetails(false);
  //     message.info("Email sent successfully");
  //     setLoaderSendEmail(false);
  //   }, 2000);
  // };

  const handleSearchMessage = () => {
    setLoaderSendMessage(true);
    setTimeout(() => {
      message.info("Message sent successfully");
      setShowTicketDetails(false);
      setLoaderSendMessage(false);
    }, 2000);
  };


  const generateTicketId = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    return `${randomNumber.toString().padStart(6, '0')}`;
  };


  ///TicketLLM:
  const TicketTemplate = () => {
    setLoaderTicketSend(true);
    console.log(checkedItems, "checkedItems");
    try {
      const description = form.getFieldValue('ticketDescription');
      const input = {
        event_type: "mail_trigger",
        conversation_id: props.ConvoID,
        Description: checkedItems.join(',')
      };

      console.log(input, "template_input");
      dispatch(tickettemplate(input))
        .unwrap()
        .then(({ data }) => {
          const response = JSON.parse(data.aivolvex_control_plane);
          message.info("Mail sent successfully")
          // let answerArray = response.answer;
          // if (!Array.isArray(answerArray)) {
          //   answerArray = [answerArray];
          // }
          // setCheckedItems(answerArray);
          // form.setFieldsValue({ ticketDescription: answerArray.join('\n') }); 
          setLoaderTicketSend(false);
          setIsSuccessModalVisible(false)
        })
        .catch((err) => {


          console.log(err);
          setLoaderTicketSend(false);
        });
    } catch (error) {
      console.error(error);
    }
  };


  const FormatTicket = () => {

    console.log(checkedItems, "checkedItems")
    if (!checkedItems || checkedItems.length === 0) {
      return;
    }
    try {
      const input = {
        event_type: "ticket_llm",
        chat: checkedItems.join(',')
      };
      setIconLoader(true);
      console.log(input, "message_input");
      dispatch(ticketllm(input))
        .unwrap()
        .then(({ data }) => {
          const response = JSON.parse(data.aivolvex_control_plane);
          let answerArray = response.answer;
          if (!Array.isArray(answerArray)) {
            answerArray = [answerArray];
          }
          setCheckedItems(answerArray);
          form.setFieldsValue({ ticketDescription: answerArray.join('\n') });
          setIconLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setIconLoader(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // const hanldeTicketSubmission = () => {
  //   setIsAddIcon(false);
  // };
  const hanldeTicketSubmission = () => {
    // form.validateFields().then(values => {
    //   console.log('Form values:', values);
    //   // Handle form submission
      setIsSuccessModalVisible(true);
    // }).catch(errorInfo => {
    //   console.log('Validation failed:', errorInfo);
    // });
  };



  useEffect(() => {
    if (isModalVisible) {
      setTicketId(generateTicketId());
      console.log(generateTicketId())
    }
  }, [isModalVisible]);
  console.log("test")



  const handlesubmitticketdetails = () => {
    
    setIsAddIcon(false);
  };

  return (
    <>

      <Dropdown
        menu={{
          items,
          onClick,
        }}
        placement="bottomRight"
      >
        <a onClick={(e) => e.preventDefault()}>
          <Icon className="ticket-raise" icon="typcn:ticket" width="1.6rem" height="1.6rem" />
        </a>
      </Dropdown>

      <Modal
        title={<span className="ticket-raise">Create Ticket</span>}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmitbutton}>
          <Form.Item label="Ticket ID" name="ticketId">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Ticket Title"
            name="ticketTitle"
            rules={[{ required: true, message: 'Please input the ticket title!' }]}
          >
            <Input placeholder="Ticket Title" />
          </Form.Item>

          <Form.Item label="Ticket Description">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon="uil:plus-circle" className="ticket-raise cursor" onClick={Addicon} />
              <Button className="ms-2 format-cs" onClick={FormatTicket}>
                {!iconloader ? (
                  <Icon icon="mdi:format-rotate-90" width="1.6em" height="1.6em" />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                      opacity={0.25}
                    ></path>
                    <path
                      fill="currentColor"
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                    >
                      <animateTransform
                        attributeName="transform"
                        dur="0.75s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      ></animateTransform>
                    </path>
                  </svg>
                )}
              </Button>
            </div>
          </Form.Item>

          {/* Nested Modal for Adding Icons */}
          <Modal
            title={<span className="ticket-raise">Create Ticket</span>}
            visible={isAddIcon}
            onCancel={handleCancelAdd}
            footer={[
              <Button
                onClick={handlesubmitticketdetails}
                loading={false}
                className="mt-4 sub-btn text-center custom-modal-footer"
              >
                Submit Ticket
              </Button>,
            ]}
            width={900}
            bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
          >
            <CustomerSummary setCheckedItems={setCheckedItems} checkedItems={checkedItems} />
          </Modal>
          {console.log(checkedItems, "checkkk")}

          <Form.Item
            rules={[{ required: true, message: 'Please input the ticket description!' }]}
          >
            <TextArea
              value={checkedItems.join("\n")}
              placeholder="Ticket Description"
              onChange={handleTextAreaChange}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>

          <Form.Item
            label="Ticket Priority"
            name="ticketPriority"
            rules={[{ required: true, message: 'Please select the ticket priority!' }]}
          >
            <Select placeholder="Select ticket priority">
              <Option value="low">Low</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Ticket Status"
            name="ticketStatus"
            rules={[{ required: true, message: 'Please select the ticket status!' }]}
          >
            <Select placeholder="Select ticket status">
              <Option value="in-progress">In Progress</Option>
              <Option value="closed">Completed</Option>
            </Select>
          </Form.Item>

          <div className="button-container mt-4 d-flex justify-content-center">
            <Button
              htmlType="submit"
              loading={loaderstatus1}
              onClick={hanldeTicketSubmission}
              className="mt-4 sub-btn"
            >
              Submit Ticket
            </Button>
          </div>
        </Form>
      </Modal>


      <Modal
        title={<span className="ticket-raise">Ticket Configurations</span>}
        visible={ShowTicketDetails}
        onCancel={handleCancelTicket}
        footer={null}
        className="report-table-modal"
        width={500}
      >
        {selectedRow && (
          <div>
            <div className="d-flex justify-content-between">
              <p>
                <b className="ticket-raise">Ticket ID: </b>
                {selectedRow.ticketId}
              </p>
              <p>{renderPriorityIcon(selectedRow.Priority)}</p>
            </div>
            <p>
              <b className="ticket-raise">Ticket Title: </b>
              {selectedRow.ticketTitle}
            </p>
            <p>
              <b className="ticket-raise">Description: </b>
              {selectedRow.description}
            </p>
            <p>
              <b className="ticket-raise">Status:</b> {selectedRow.Status}
            </p>
            <p>
              <b className="ticket-raise">History Status:</b>
            </p>
            <ul className="text-white">
              {selectedRow.statusHistory.map((history, index) => (
                <li key={index} className="text-white">
                  {history.date}: {history.status}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <Button
            type="button"
            onClick={handleSendEmail}
            loading={LoaderSendEmail}
            className="mt-4 sub-btn"
          >
            Send Email
          </Button>
          <Button
            type="button"
            onClick={handleSearchMessage}
            loading={LoaderSendMessage}
            className="mt-4 ms-2 sub-btn"
          >
            Send Message
          </Button>
        </div>
      </Modal>
      <Modal
            title={<span className="ticket-raise">Search Ticket</span>}
            visible={isModalVisible2}
            onCancel={handleCancel}
            footer={null}
            width={1000}
            className="height-modal"
          >
            <div className="w-100">
           
              <Search
                placeholder="Search Ticket"
                onSearch={handleSearch}
                style={{ width: 500 }}
              />
            </div>
            <div className="mt-4">
              <Table
                dataSource={searchText ? dataSource : data}
                columns={columns}
                pagination={false}
                className="record-dashboard-table"
              />
            </div>
          </Modal>
          <Modal
            // title={<span className="modal-title">Success</span>}
            visible={isSuccessModalVisible}
            onCancel={handleSuccessClose}
            footer={false}
            className="custom-modal"
          >
            <p>Ticket has been submitted successfully!</p>
            <div className="d-flex justify-content-center">
              <Button
                type="button"
                onClick={TicketTemplate}
                loading={LoaderTicketSend}
                className="mt-4 sub-btn"
              >
                Send Email
              </Button>
              <Button
                type="button"
                onClick={handleSendMessage}
                loading={LoaderSendMessage}
                className="mt-4 ms-2 sub-btn"
              >
                Send Message
              </Button>
            </div>
          </Modal>
    </>
  );

}

export default TicketSys;