import React, { useState, useEffect, useRef } from "react";
import {
    Col, Row, Card, Button, Avatar, Input,
    Modal, Select, message
} from "antd";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import StepperChatTime from "./StepperChatTime";
import TicketSys from "./TicketSys";
import OpportunityTooltip from "./OpporunityTooltip";
import IssueTooltip from "./IssueTooltip";
import { v4 as uuidv4 } from 'uuid';
import Tooltip from "./ToolTip";
import TooltipSentiment from "./TooltipSentiment";

import {
    customerchat,
    chatconnection,
    customerprofile,
    chatsummary,
    previousconversation,
    kbcontent,
    sentimentinfo,
    postcallapi,
    formatllm
} from "../../../api/accountAction";

import "../../CSS/Dashboard.css";
import { setConversationID, setQuestion, setClientTicketRaise } from "../../../Slice/reducer/chatReducer";
import PositiveImg from "../../Images/positive.svg"
import NegativeImg from "../../Images/negative.svg"
import NeutralImg from "../../Images/neutral.svg"
import { useNavigate } from "react-router-dom";
import ModalPopUpCCI from "./ModalPopUpCCI";


const LiveCallPage = () => {
    const { TextArea } = Input;

    const { Option } = Select;
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { AcceptCoversationID, AgentConnectionId } = useSelector((state) => state.chat);

    const [inputValue, setInputValue] = useState("");
    const [ChatInputValue, setChatInputValue] = useState("");
    const [SubmittedValue, setSubmittedValue] = useState("");
    const [loaderstatus, setLoaderStatus] = useState(false);

    const inputRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [clearInput, setClearInput] = useState("");
    const [loaderstatus1, setLoaderStatus1] = useState(false);
    const [AgentConnevtionValue, setAgentConnectionValue] = useState("")
    const [CustomerProfile, setCustomerProfile] = useState("")
    const [SummaryChat, setSummaryChat] = useState("")
    const [PreviousConvo, setPreviousConvo] = useState([]);
    const [ConvoID, setConvoID] = useState("");
    const [knowledgebase, setKnowledgeBase] = useState([])
    const [AnswerValue, setAnswerValue] = useState("");
    const [QuestionID, setQuestionID] = useState("");
    const [SentimentInfo, setSentimentInfo] = useState("")
    const [previousentiment, setpreviousentiment] = useState("")
    const [PostCallLoader, setPostCallLoader] = useState(false);
    const [inputCopy, setinputCopy] = useState('');
    const [KBCopy, setKBCopy] = useState('');
    const [lastQuesid, setLastQuesid] = useState(null);
    const [iconloader, setIconLoader] = useState(false);
    const [iconloaderkb, setIconLoaderKB] = useState(false);
    const [isticketopen, setIsTicketOpen] = useState(false)
    const [OpporunityStatus, setOpporunityStatus] = useState("")
    const [ExistingQuestion, setExistingQuestion] = useState("")
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [uniqueIds, setUniqueIds] = useState(new Set());
    const chatBodyRef1 = useRef(null);


    const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
        ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
        : "";
    const [LoaderSendMessage, setLoaderSendMessage] = useState(false);
    const [LoaderSendEmail, setLoaderSendEmail] = useState(false);
    const [MessageRecords, setMessageRecords] = useState("")

    const [ticketId, setTicketId] = useState("");

    const handleSendMessage = () => {
        setLoaderSendMessage(true);
        setTimeout(() => {
            message.info("Message sent successfully");
            setIsSuccessModalVisible(false);
            setLoaderSendMessage(false);
        }, 2000);
    };

    const handleSendEmail = () => {
        setLoaderSendEmail(true);
        setTimeout(() => {
            setIsSuccessModalVisible(false);
            message.info("Email sent successfully");
            setLoaderSendEmail(false);
        }, 2000);
    };

    const handleSuccessClose = () => {
        setIsSuccessModalVisible(false);
    };


    const handleSubmitbutton = async () => {
        setLoaderStatus1(true);
        setTimeout(() => {
            setIsSuccessModalVisible(true);
            // setIsTicketOpen(false);
            setLoaderStatus1(false);
        }, 2000);
    };


    const generateTicketId = () => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        return `${randomNumber.toString().padStart(6, "0")}`;
    };












    useEffect(() => {
        if (isticketopen) {
            setTicketId(generateTicketId());
        }
    }, [isticketopen]);


    useEffect(() => {
        openWebsocket()
        profilecustomer()
        summarychat()
        previousconvo()
        setConvoID(AcceptCoversationID)

    }, [AcceptCoversationID])

    const ChatConnectionAPI = (connectionIdValue) => {
        const input = {
            event_type: "chat_connection",
            connection_id: connectionIdValue,
            email_id: userName,
        };

        dispatch(chatconnection(input))
            .unwrap()
            .then(({ data }) => {

                const response = data
                const conversationid = JSON.parse(response.aivolvex_control_plane)

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openWebsocket = () => {
        const socket = new WebSocket(
            "wss://wduoawp287.execute-api.ap-south-1.amazonaws.com/production/"
        );
        socket.onopen = (e) => {
            socket.send(JSON.stringify({ type: "connection_init" }));
        };
        socket.onmessage = (e) => {
            const chatmessage = JSON.parse(e.data);
            dispatch(setConversationID(chatmessage.conversation_id))
            dispatch(setQuestion(chatmessage.question))
            dispatch(setClientTicketRaise(chatmessage.question))
            setQuestionID(chatmessage.id)

            if (chatmessage.question) {
                const newMessage = {
                    type: "agent",
                    message: chatmessage.question,
                };
                setClearInput(Math.random());
                setLoaderStatus1(false);
                Stepperchart(chatmessage.id, chatmessage.conversation_id, chatmessage.question, "qus", "new", '')
            }
            if (chatmessage && chatmessage.message === "Forbidden") {
                ChatConnectionAPI(chatmessage.connectionId)
            } else if (chatmessage) {
                const value = chatmessage;

                return socket;
            }
        };
        socket.onerror = (e) => {
            console.error('WebSocket error:', e);

        };
        socket.onclose = (e) => {

        };
        return socket;
    };

    const chatconversation = () => {
        try {
            if (inputRef.current) {
                // var inputMessage = KBCopy;
                var inputMessage = KBCopy.trim();
                if (!inputMessage) {

                    return;
                }
                setKBCopy('');
                var qus_id = lastQuesid

                if (QuestionID) {
                    qus_id = QuestionID;
                }
                Stepperchart(qus_id, ConvoID, inputMessage, "ans", '', '');
                const input = {
                    event_type: "threewayagent",
                    connection_id: AgentConnectionId,
                    ID: qus_id,
                    answer: inputMessage,
                };
                setLoaderStatus1(true);

                const newMessageResponse = {
                    type: "ans",
                    message: inputMessage,
                };

                setClearInput(Math.random());

                dispatch(customerchat(input))
                    .unwrap()
                    .then(({ data }) => {
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formLLM = () => {

        try {
            if (inputRef.current && KBCopy) {

                var inputmessage = inputRef.current.input.value ? inputRef.current.input.value : KBCopy;
                if (!inputmessage) {

                    return;
                }
                const input = {
                    event_type: "format_llm",
                    chat: inputmessage

                }
                setIconLoader(true);
                // setLoaderStatus1(true);
                setClearInput(Math.random());
                // setLoaderStatus1(true);
                dispatch(formatllm(input))
                    .unwrap()
                    .then(({ data }) => {
                        setKBCopy(JSON.parse(data.aivolvex_control_plane).answer);
                        setIconLoader(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setIconLoader(false);
                    });
            }
        } catch (error) {
            console.error(error);
        }
    };


    const KbFormatLLM = () => {

        try {
            if (inputRef.current && inputRef.current.input) {
                const inputMessage = inputRef.current.input.value.trim();

                if (!inputMessage) {
                    return;
                }

                inputRef.current.input.value = "";

                const input = {
                    event_type: "format_llm",

                    chat: inputMessage

                }
                setIconLoaderKB(true);
                setClearInput(Math.random());
                dispatch(formatllm(input))
                    .unwrap()
                    .then(({ data }) => {

                        setinputCopy(JSON.parse(data.aivolvex_control_plane).answer);
                        setIconLoaderKB(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setIconLoaderKB(false);
                    });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlTextArea = (event) => {
        setMessageRecords(event.target.value);
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // const Handleticket =() =>{
    //     console.log("inputtt")
    //     setIsTicketOpen(true);
    // }


    const Handleticket = (message) => {
        setMessageRecords(message.records);
        setIsTicketOpen(true);
        // Your existing Handleticket logic here
    };


    const handleticketcancel = () => {
        setIsTicketOpen(false);
    }

    const HandleChatMessage = (e) => {
        setChatInputValue(e.target.value)
    };

    const handleSubmit = () => {

        setChatInputValue("");

    };

    const handlechatsubmit = () => {

        setSubmittedValue(inputValue);
        setInputValue("");
        // kbcontent(inputValue);
    };

    const handleKeyPresschat = (e) => {
        if (e.key === "Enter") {
            handleknowledgebase()
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const period = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12 || 12;
        hours = String(hours).padStart(2, '0');

        return `${year}-${month}-${day}, ${hours}:${minutes}${period}`;
    }


    const profilecustomer = () => {
        const input = {
            event_type: "customer_profile",
            conversation_id: AcceptCoversationID
        };

        dispatch(customerprofile(input))
            .unwrap()
            .then(({ data }) => {
                const response = data
                setCustomerProfile(JSON.parse(response.aivolvex_control_plane))

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const summarychat = () => {
        const input = {
            event_type: "chat_summary",
            conversation_id: AcceptCoversationID
        };

        dispatch(chatsummary(input))
            .unwrap()
            .then(({ data }) => {
                const response = data
                setSummaryChat(JSON.parse(response.aivolvex_control_plane))

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const previousconvo = () => {
        const input = {
            event_type: "previous_convo",
            conversation_id: AcceptCoversationID
        };

        dispatch(previousconversation(input))
            .unwrap()
            .then(({ data }) => {

                const parsedData = JSON.parse(data.aivolvex_control_plane);

                const mappedData = Array.isArray(parsedData)
                    ? parsedData.map((item) => ({
                        type: item.transcript_type === "qus" ? "agent" : "answer",
                        transcript_type: item.transcript_type,
                        transcript: item.transcript,
                        conversation_id: item.conversation_id,
                        id: item.id
                    }))
                    : [];

                const lastQuesid = mappedData.length > 0 ? mappedData[mappedData.length - 1].id : null;

                setLastQuesid(lastQuesid);

                if (mappedData.length > 0) {
                    mappedData.forEach((item) => {
                        if (item.type === "agent") {
                            dispatch(setClientTicketRaise(item.transcript));
                        }
                    });
                }

                if (mappedData.length > 0) {
                    const newItems = mappedData.filter(item => !uniqueIds.has(item.id));
                    newItems.forEach((item, index) => {
                        uniqueIds.add(item.id);
                        Stepperchart(item.id, item.conversation_id, item.transcript, item.transcript_type, "old", index);
                    });
                    setUniqueIds(new Set(uniqueIds));
                }


            })
            .catch((err) => {
                console.log(err);
            });
    };


    // useEffect(() => {
    //     if (chatBodyRef.current) {
    //         chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    //         console.log('Scroll height:', chatBodyRef.current.scrollHeight);
    //     }
    // }, [messages]);


    const handleknowledgebase = () => {

        setinputCopy('')
        try {
            if (inputRef.current && inputRef.current.input) {
                const inputMessage = inputRef.current.input.value.trim();

                if (!inputMessage) {
                    return;
                }

                inputRef.current.input.value = "";

                const input = {
                    event_type: "private_kb",
                    avatar_id: "720",
                    conversation_id: ConvoID,
                    chat: inputMessage,
                };
                setLoaderStatus(true)
                const newMessageResponse = {
                    type: "agent",
                    message: inputMessage,
                };


                setKnowledgeBase(prev => [...prev, newMessageResponse]);

                dispatch(kbcontent(input))
                    .unwrap()
                    .then(({ data }) => {
                        const response = JSON.parse(data.aivolvex_control_plane);

                        const newMessageResponse = {
                            type: "ans",
                            message: response,
                        };
                        setKnowledgeBase((prev) => [...prev, newMessageResponse]);
                        setLoaderStatus(false);
                    })
                setClearInput(Math.random());

            }
        } catch (error) {
            console.error(error);
            setLoaderStatus(false);
        }
    };

    const handlepostcall = () => {
        setPostCallLoader(true)
        const input = {
            event_type: "postcall",
            conversation_id: ConvoID,
            chat_type: "private"
        };

        dispatch(postcallapi(input))
            .unwrap()
            .then(({ data }) => {

                navigate(`/postchat/${ConvoID}`);
                setPostCallLoader(false)

            })
            .catch((err) => {
                console.log(err);
                navigate(`/postchat/${ConvoID}`);
                setPostCallLoader(false)
            });
    }


    let indexCounter = 0;

    const Stepperchart = (id, convoid, message, type, msg_type, index = null) => {

        const input = {
            event_type: "sentiment_info",
            ID: id,
            conversation_id: convoid,
            question: message,
            type: type
        };


        dispatch(sentimentinfo(input))
            .unwrap()
            .then(({ data }) => {
                const response = JSON.parse(data.aivolvex_control_plane);

                let opportunity_res = {
                    response: response,
                    type: type
                };
                setSentimentInfo(opportunity_res);



                if (index === null) {
                    index = indexCounter++;
                }

                let result_value = {
                    index: index,
                    records: message,
                    msg_type: msg_type,
                    type: type,
                    response: response,
                };

                if (msg_type === "old") {
                    setMessages((prev) => {
                        const newMessages = [...prev, result_value];
                        newMessages.sort((a, b) => a.index - b.index);
                        return newMessages;
                    });
                }
                else {
                    setMessages((prev) => {
                        const newMessages = [...prev, result_value];
                        return newMessages;
                    });
                }

                setLoaderStatus1(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handleCopy = (text) => {
        setinputCopy(text);
    };


    const handleKB = (text) => {
        setKBCopy(text);
    };

    const handleKeyPressChat = (e) => {
        if (e.key === "Enter") {
            chatconversation();
        }
    };

    const handleKeyPressKB = (e) => {
        if (e.key === "Enter") {
            handleknowledgebase();

        }
    };


    useEffect(() => {
        if (messages.length > 0) {
            const lastMessageEl = document.getElementById(`message-${messages.length - 1}`);
            if (lastMessageEl) {
                lastMessageEl.scrollIntoView({ behavior: 'smooth' });
                void lastMessageEl.offsetHeight;
            }
        }
    }, [messages]);


    //   useEffect(() => {
    //     if (knowledgebase.length > 0) {
    //       const lastMessagekb = document.getElementById(`knowledgebase-${knowledgebase.length - 1}`);
    //       if (lastMessagekb) {
    //         lastMessagekb.scrollIntoView({ behavior: 'smooth' });
    //       }
    //     }
    //   }, [knowledgebase]);

    useEffect(() => {
        if (knowledgebase.length > 0) {
            const lastMessagekb = document.getElementById(`knowledgebase-${knowledgebase.length - 1}`);
            if (lastMessagekb) {
                lastMessagekb.scrollIntoView({ behavior: 'smooth' });
                void lastMessagekb.offsetHeight;
            }
        }
    }, [knowledgebase]);

    //     const chatBodyRef = useRef(null);

    // useEffect(() => {
    //     if (chatBodyRef.current) {
    //         chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    //     }
    // }, [knowledgebase]);


    return (
        <>
        {/* <><ModalPopUpCCI/></> */}
            <Col span={24} className="aivolvex-main-body">
                <h4 className="dashboard-credit aivolvex-font text-center">
                    Live Chat Analytics
                </h4>
                <Row className="mt-3 d-flex justify-content-between" gutter={[16, 16]}>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <div className="aivolvex-dashboard-outline full-width">
                            <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                                <h4 className="dashboard-credit aivolvex-font font-size-title">

                                    Customer Profile
                                </h4>
                                    <Col className="pca-df text-white">
                                    <div className="customer-detail pt-2">
                                    <b>Customer Name</b> <span className="colon">:</span>&nbsp; {CustomerProfile.user_name}
                                    </div>
                                    <div className="customer-detail pt-2">
                                    <b>Phone Number</b> <span className="colon">:</span>&nbsp; {CustomerProfile.mobile_number}
                                    </div>
                                    <div className="customer-detail pt-2">
                                    <b>Email ID</b> <span className="colon">:</span>&nbsp; {CustomerProfile.email_id}
                                    </div>
                                    </Col>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24} >
                        <div className="aivolvex-dashboard-outline full-width ">
                            <div className="Dashboard_graph_box Dashboard_head_box_shadow chat-height">
                                <Row>
                                    <Col span={20}>
                                        <div>
                                            <h4 className="dashboard-credit aivolvex-font font-size-title">
                                                Chat Summary
                                            </h4>
                                        </div>
                                    </Col>

                                </Row>
                                <Row className="text-white">
                                    {SummaryChat && (
                                        <>
                                            <Col span={24}>
                                                <b>{SummaryChat.Title}</b>
                                            </Col>
                                            <Col span={24} className="scrollable-summary">
                                                <p>{SummaryChat.Summary}</p>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>

                        <div className="aivolvex-dashboard-outline full-width">
                            <div className="Dashboard_graph_box Dashboard_head_box_shadow call-height">
                                <Row className=" d-flex align-items-center">
                                    <Col span={19}>
                                        <h4 className="dashboard-credit aivolvex-font font-size-title">
                                            Chat Transcription
                                        </h4>
                                    </Col>
                                    <Col span={5} className="text-end pt-1 pe-3 ">
                                        <div className="select-lang">
                                            <TicketSys ConvoID={ConvoID}/>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="scrollable-transcript">
                                    <Col span={24}>
                                        <div className="pca-chatbot-body">
                                            {messages && messages.map((msg, index) => (
                                                <div key={index} id={`message-${index}`}>
                                                    <>
                                                        {msg.type === "qus" ? (
                                                            <div className="d-flex justify-content-start mt-2">
                                                                <Avatar className="pca-chatbot-ai" size="large">C</Avatar>
                                                                <div className="pca-ai-message pca-width">
                                                                    <span>{msg.records}</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex justify-content-end mt-2">
                                                                <div className="pca-ai-message-replay">
                                                                    <span>{msg.records}</span>
                                                                </div>
                                                                <Avatar className="pca-chatbot-ai-replay" size="large">A</Avatar>
                                                            </div>
                                                        )}

                                                        {msg.type === "qus" && msg.response && (
                                                            <div className="d-flex justify-content-start margin-lef">
                                                                <TooltipSentiment title={<span>Negative</span>}>
                                                                    {msg.response.Overall_sentiment === "Negative" && (
                                                                        <img src={NegativeImg} alt="Negative Sentiment Logo" />
                                                                    )}
                                                                </TooltipSentiment>

                                                                <TooltipSentiment title={<span className="Opp-tooltip">Neutral</span>}>
                                                                    {msg.response.Overall_sentiment === "Neutral" && (
                                                                        <img src={NeutralImg} alt="Neutral Sentiment Logo" />
                                                                    )}
                                                                </TooltipSentiment>

                                                                <TooltipSentiment title={<span className="Opp-tooltip">Positive</span>}>
                                                                    {msg.response.Overall_sentiment === "Positive" && (
                                                                        <img src={PositiveImg} alt="Positive Sentiment Logo" />
                                                                    )}
                                                                </TooltipSentiment>

                                                                {msg.response.Opportunity_status === "positive" && (
                                                                    <Tooltip content={<OpportunityTooltip response={msg.response} />}>
                                                                        <Icon
                                                                            icon="ph:warning-circle-fill"
                                                                            width="1.7em"
                                                                            height="1.7em"
                                                                            className="green-warning"
                                                                        />
                                                                    </Tooltip>
                                                                )}

                                                                {msg.response.Opportunity_status === "negative" && (
                                                                    <Tooltip content={<IssueTooltip response={msg.response} />}>
                                                                        <Icon
                                                                            icon="ph:warning-circle-fill"
                                                                            width="1.7em"
                                                                            height="1.7em"
                                                                            className="red-warning"
                                                                        />
                                                                    </Tooltip>
                                                                )}

                                                                {msg.response.Ticket_Opportunity === "Yes" && msg.response.Opportunity_status === "negative" && (
                                                                    <Icon
                                                                        onClick={() => Handleticket(msg)}
                                                                        className="ticket-color"
                                                                        icon="typcn:ticket"
                                                                        width="1.5rem"
                                                                        height="1.5rem"
                                                                    />
                                                                )}

                                                                <Modal
                                                                    title={<span className="ticket-raise">Create Ticket</span>}
                                                                    visible={isticketopen}
                                                                    onCancel={handleticketcancel}
                                                                    footer={false}
                                                                    width={1000}
                                                                >
                                                                    <div>
                                                                        <h6 className="mt-4">Ticket ID</h6>
                                                                        <Input placeholder="Ticket ID" value={ticketId} disabled />
                                                                        <h6 className="mt-4">Ticket Title</h6>
                                                                        <Input placeholder="Ticket Title" />
                                                                        <h6 className="mt-4">Ticket Description</h6>
                                                                        <TextArea
                                                                            onChange={handlTextArea}
                                                                            value={MessageRecords}
                                                                            placeholder="Ticket Description"
                                                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                                                        />
                                                                        <h6 className="mt-4">Ticket Priority</h6>
                                                                        <Select placeholder="Select ticket priority" style={{ width: "100%" }}>
                                                                            <Option value="low">Low</Option>
                                                                            <Option value="medium">Medium</Option>
                                                                            <Option value="high">High</Option>
                                                                        </Select>
                                                                        <h6 className="mt-4">Ticket Status</h6>
                                                                        <Select placeholder="Select ticket status" style={{ width: "100%" }}>
                                                                            <Option value="in-progress">In Progress</Option>
                                                                            <Option value="closed">Completed</Option>
                                                                        </Select>
                                                                    </div>
                                                                    <div className="button-container mt-4">
                                                                        <Button
                                                                            onClick={handleSubmitbutton}
                                                                            loading={loaderstatus1}
                                                                            className="mt-4 d-flex justify-content-center sub-btn"
                                                                        >
                                                                            Submit Ticket
                                                                        </Button>
                                                                    </div>
                                                                </Modal>

                                                                <Modal
                                                                    visible={isSuccessModalVisible}
                                                                    onCancel={handleSuccessClose}
                                                                    footer={false}
                                                                    className="custom-modal"
                                                                >
                                                                    <p>Ticket has been submitted successfully!</p>
                                                                    <div className="d-flex justify-content-center">
                                                                        <Button
                                                                            type="button"
                                                                            onClick={handleSendEmail}
                                                                            loading={LoaderSendEmail}
                                                                            className="mt-4 sub-btn"
                                                                        >
                                                                            Send Email
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            onClick={handleSendMessage}
                                                                            loading={LoaderSendMessage}
                                                                            className="mt-4 ms-2 sub-btn"
                                                                        >
                                                                            Send Message
                                                                        </Button>
                                                                    </div>
                                                                </Modal>

                                                                <Icon
                                                                    icon="material-symbols:convert-to-text-outline"
                                                                    width="1.4em"
                                                                    height="1.4em"
                                                                    className="ticket-raise ms-1"
                                                                    onClick={() => handleCopy(msg.records)}
                                                                />
                                                            </div>
                                                        )}
                                                    </>
                                                </div>
                                            ))}
                                        </div>


                                    </Col>

                                </Row>
                                <Col span={24}>
                                    <div className="margin-top" style={{ width: '615px' }} >
                                        <Input
                                            className="text-in-input"
                                            key={clearInput}
                                            ref={inputRef}
                                            value={KBCopy}
                                            onKeyPress={handleKeyPressChat}
                                            onChange={(e) => setKBCopy(e.target.value)}
                                        />

                                        <Button className="ms-2 format-cs" onClick={formLLM} >
                                            {
                                                !iconloader ? <> <Icon icon="fluent:text-clear-formatting-16-regular"
                                                    width="1.6em"
                                                    height="1.6em"
                                                />
                                                </> : <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity={0.25}></path><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform></path></svg>
                                                </>
                                            }
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="sub-btn ms-2"
                                            onClick={chatconversation}

                                        > {
                                                !loaderstatus1 ? 'Submit' :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity={0.25}></path>
                                                        <path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z">
                                                            <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform>
                                                        </path>
                                                    </svg>
                                            }
                                        </Button>

                                    </div>

                                </Col>


                            </div>

                        </div>


                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <div className="aivolvex-dashboard-outline full-width">

                                    <div className="Dashboard_graph_box Dashboard_head_box_shadow" >

                                        <Row className=" d-flex align-items-center">
                                            <Col span={24}>
                                                <h4 className="dashboard-credit aivolvex-font font-size-title">
                                                    Knowledge Base Centre - Agent Assist
                                                </h4>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col span={24} className="scrollable-content">
                                                <div className="pca-chatbot-body" ref={chatBodyRef1}>
                                                    {knowledgebase.map((msg, index) => {
                                                        return (
                                                            <div key={index} id={`knowledgebase-${index}`}>
                                                                {msg.type == "ans" ? (
                                                                    <>
                                                                        <div className="d-flex justify-content-start mt-2">
                                                                            <div>
                                                                                <Avatar className="pca-chatbot-ai" size="large">
                                                                                    AI
                                                                                </Avatar>
                                                                            </div>
                                                                            <div className="pca-ai-message">
                                                                                <span>{msg.message}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start knowl">
                                                                            <Icon
                                                                                icon="material-symbols:convert-to-text-outline"
                                                                                width="1.4em"
                                                                                height="1.4em"
                                                                                className="ticket-raise"
                                                                                onClick={() => handleKB(msg.message)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="d-flex justify-content-end mt-2">
                                                                        <div className="pca-ai-message-replay">
                                                                            <span>
                                                                                <Markdown>{msg.message}</Markdown>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <Avatar className="pca-chatbot-ai-replay" size="large">
                                                                                A
                                                                            </Avatar>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </Col>

                                            <Col span={24}>
                                                <div className="" style={{ width: '615px' }}>
                                                    <Input
                                                        className="text-in"
                                                        key={clearInput}
                                                        ref={inputRef}
                                                        onKeyPress={handleKeyPressKB}
                                                        value={inputCopy}
                                                        onChange={(e) => setinputCopy(e.target.value)}
                                                    />

                                                    <Button className="ms-2 format-cs" onClick={KbFormatLLM} >
                                                        {
                                                            !iconloaderkb ? <> <Icon icon="fluent:text-clear-formatting-16-regular"
                                                                width="1.6em"
                                                                height="1.6em"
                                                            />
                                                            </> : <>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity={0.25}></path><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform></path></svg>
                                                            </>
                                                        }

                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        className="sub-btn ms-2"
                                                        onClick={handleknowledgebase}
                                                    // loading={loaderstatus}
                                                    >
                                                        {
                                                            !loaderstatus ? 'Submit' :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity={0.25}></path>
                                                                    <path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z">
                                                                        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform>
                                                                    </path>
                                                                </svg>
                                                        }
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </div>

                            </Col>

                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <div className="aivolvex-dashboard-outline full-width ">
                                    <div className="Dashboard_graph_box Dashboard_head_box_shadow height-stepper" >
                                        <StepperChatTime SentimentInfo={SentimentInfo} />
                                    </div></div>

                            </Col>

                        </Row>
                    </Col>

                </Row >
                <Row span={24} className="d-flex justify-content-center mt-2">

                    <Button
                        type="submit"
                        className="sub-btn post-btn ms-4"
                        onClick={handlepostcall}
                        loading={PostCallLoader}>
                        Post Chat
                    </Button>
                </Row>

            </Col >

        </>
    )
}


export default LiveCallPage;