import { Tabs, Row, Col } from "antd";
import Profileform from "./Profileform";
import Profileinfo from "./Profileinfo";
import { useDispatch, useSelector } from "react-redux";

const Profilemainmenu = () => {
  const { offerstatus } = useSelector((state) => state.chat);
  const items = [
    {
      key: "1",
      label: "Profile",
      children: <Profileinfo />,
    },
    {
      key: "2",
      label: "Organization",
      children: <Profileform />,
    },
  ];
  const filteredItems = offerstatus
    ? items
    : items.filter((item) => item.key !== "2");
  return (
    <>
      <div className="aivolvex-main-body mobile-account-body">
        <Row className="recent-prompt-table-head">
          <Col span={24}>
            <h4 className="theme-title-font">Account Settings</h4>
          </Col>
        </Row>
        <Row className="profile-tab-body">
          <Col span={24}>
            <Tabs className="tab-tags" items={filteredItems} />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Profilemainmenu;
