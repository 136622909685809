import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";

const Sidemenu = (props) => {
  const { offerstatus } = useSelector((state) => state.chat);
  const sidebarmenu = props.sidebarmenu;
  const [isExpanded, setIsExpanded] = useState(true);
  const Autologin = localStorage.getItem("autologin");

  return (
    <>
      <Sidebar className="costimize-sidebar sidebar-desktop">
        <Menu>
       
          {Autologin === "true" ? (
            <>    
            

              <MenuItem
                className={sidebarmenu === 10 ? "active active-menu-item" : ""}
                component={<Link to="/live-agent" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                    icon="hugeicons:chat-bot"
                   
                    id="library"
                    className={`DashboardNavicon ${sidebarmenu === 10 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 10 ? "pink-icon" : ""}`}>
                  Live Agent
                </div>
              </MenuItem>

                {/* <MenuItem
                className={sidebarmenu === 11 ? "active active-menu-item" : ""}
                component={<Link to="/livecall" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                    icon="hugeicons:chat-bot"
                    id="library"
                    className={`DashboardNavicon ${sidebarmenu === 11 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 11 ? "pink-icon" : ""}`}>
                  Support Agent
                </div>
              </MenuItem> */}
            </>
          ) : (
            <>
              {/* Render all menus if autologin is false */}
              <MenuItem
                className={sidebarmenu === 1 ? "active active-menu-item" : ""}
                component={<Link to="/conversation" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                    icon="gg:profile"
                    id="library"
                    className={`DashboardNavicon ${sidebarmenu === 1 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 1 ? "pink-icon" : ""}`}>
                  Agent Hub
                </div>
              </MenuItem>

              {/* <MenuItem
                className={sidebarmenu === 11 ? "active active-menu-item" : ""}
                component={<Link to="/livecall" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                    icon="hugeicons:chat-bot"
                    id="library"
                    className={`DashboardNavicon ${sidebarmenu === 11 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 11 ? "pink-icon" : ""}`}>
                  Support Agent
                </div>
              </MenuItem> */}

              <MenuItem
                className={sidebarmenu === 10 ? "active active-menu-item" : ""}
                component={<Link to="/live-agent" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                      icon="hugeicons:chat-bot"
                      height={35}
                      widths={35}
                      id="library"
                      className={`DashboardNavicon ${sidebarmenu === 10 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 10 ? "pink-icon" : ""}`}>
                  Live Agent
                </div>
              </MenuItem>
              <MenuItem
                className={sidebarmenu === 9 ? "active active-menu-item" : ""}
                component={<Link to="/public-agent" />}
              >
                <div className="nav-icon-sidebar">
                  <Icon
                    icon="gg:profile"
                    id="library"
                    className={`DashboardNavicon ${sidebarmenu === 9 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 9 ? "pink-icon" : ""}`}>
                  Public Agent
                </div>
              </MenuItem>

              <MenuItem
                className={sidebarmenu === 3 ? "active active-menu-item" : ""}
                component={<Link to="/recent_prompts" />}
              >
                <div className="nav-icon-sidebar">
                  <IoShuffleOutline
                    className={`DashboardNavicon ${sidebarmenu === 3 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 3 ? "pink-icon" : ""}`}>
                  Recent Prompts
                </div>
              </MenuItem>

              <MenuItem
                className={sidebarmenu === 4 ? "active active-menu-item" : ""}
                component={<Link to="/history" />}
              >
                <div className="nav-icon-sidebar">
                  <IoIosTimer
                    className={`DashboardNavicon ${sidebarmenu === 4 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 4 ? "pink-icon" : ""}`}>
                  History
                </div>
              </MenuItem>

              {offerstatus && (
                <>
                  <MenuItem
                    className={sidebarmenu === 7 ? "active active-menu-item" : ""}
                    component={<Link to="/role" />}
                  >
                    <div className="nav-icon-sidebar">
                      <Icon
                        icon="ant-design:tag-outlined"
                        className={`DashboardNavicon ${sidebarmenu === 7 ? "pink-icon" : ""}`}
                      />
                    </div>
                    <div className={`label-sidemenu ${sidebarmenu === 7 ? "pink-icon" : ""}`}>
                      Role
                    </div>
                  </MenuItem>

                  <MenuItem
                    className={sidebarmenu === 8 ? "active active-menu-item" : ""}
                    component={<Link to="/token" />}
                  >
                    <div className="nav-icon-sidebar">
                      <Icon
                        icon="material-symbols:token-outline"
                        className={`DashboardNavicon ${sidebarmenu === 8 ? "pink-icon" : ""}`}
                      />
                    </div>
                    <div className={`label-sidemenu ${sidebarmenu === 8 ? "pink-icon" : ""}`}>
                      Credits
                    </div>
                  </MenuItem>
                </>
              )}

              <MenuItem
                className={sidebarmenu === 2 ? "active active-menu-item" : ""}
                component={<Link to="/dashboard" />}
              >
                <div className="nav-icon-sidebar">
                  <IoIosKeypad
                    className={`DashboardNavicon ${sidebarmenu === 2 ? "pink-icon" : ""}`}
                  />
                </div>
                <div className={`label-sidemenu ${sidebarmenu === 2 ? "pink-icon" : ""}`}>
                  Dashboard
                </div>
              </MenuItem>
            </>
          )}
        </Menu>
      </Sidebar>
    </>
  );
};

export default Sidemenu;
