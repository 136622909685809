import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import { loginEmailcheck,AcceptCall } from "../../../api/accountAction";
import { cognitoSignIn } from "../../../Amplify/auth-config";

const AuthCCI = () => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const navigate = useNavigate();

const autologinvalue = "true"
    useEffect(() => {
        const verifyUser = async () => {
            try {
                if (token) {
                    const [header, payload] = token.split('.');
                    const decodedPayload = JSON.parse(atob(payload));

                    console.log("Decoded Payload:", decodedPayload);

                    if (decodedPayload && decodedPayload.username && decodedPayload.password) {
                        const username = decodedPayload.username;
                        const password = decodedPayload.password;

                        console.log(username, "Username");

                        const input = {
                            user_id: username,
                            password: password,
                        };

                        console.log(input, "Input for Cognito");

                        // Dispatching Cognito Sign-In
                        const signInData = await dispatch(cognitoSignIn(input)).unwrap();
                        
                        console.log(signInData, "Sign-in Data");
                        console.log(signInData.attributes.email, "User Email");

                        // Set expiration time for token
                        const expirationTime = new Date();
                        expirationTime.setTime(expirationTime.getTime() + 3600000);

                        // Store token in local storage
                        localStorage.setItem("_token", signInData.signInUserSession.accessToken.jwtToken);

                        const loginemail = { user_id: username };

                        // Dispatching login email check
                        const { data } = await dispatch(loginEmailcheck(loginemail)).unwrap();

                        console.log(data, "Login Email Data");

                        const companyName = JSON.parse(data.aivolvex_control_plane)[0].company_name;
                        const encodedCompanyName = btoa(companyName);
                        const encodedEmail = btoa(username);

                        // Store encoded values in localStorage
                        localStorage.setItem("AIVolveXIdentityServiceProvider.com", encodedCompanyName);
                        localStorage.setItem("AIVolveXIdentityServiceProvider.auth", encodedEmail);

                        // Accept Call Dispatch
                        const callaccept = {
                            event_type: "accept_agent",
                            email_id: username,
                            calltype: "available",
                        };

                        console.log(callaccept, "Call Accept Input");

                        // Dispatch AcceptCall
                        await dispatch(AcceptCall(callaccept))
                            .unwrap()
                            .then(({ data }) => {
                                console.log(data, "Accept Call Response");
                            })
                            .catch((err) => {
                                console.error("Error during call acceptance:", err);
                            });
                          

                            localStorage.setItem("autologin", autologinvalue);

                        // Redirect to live call
                        window.location.href = "/live-agent";

                      
                    } else {
                        console.error("Username or password property is missing in the decoded token.");
                    }
                } else {
                    console.error("No token found in URL parameter.");
                }
            } catch (err) {
                console.error("Error verifying user:", err);
            }
        };

        verifyUser();
    }, [dispatch, token]);

    return (
        <>
            <Spin tip="Loading..." className="spin-loader" />
        </>
    );
};

export default AuthCCI;
