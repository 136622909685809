import React, { useState, useEffect } from "react";
import { Avatar, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { AcceptCall } from "../../../api/accountAction";


function DashboardCall(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {CustomerUserName,CustomerMobileNumber,CustomerEmailID} = useSelector((state) => state.chat);

  const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
    : "";

  const handlecallpick = () => {
    props.setCallopen(false);
    agentstatus("busy")
    navigate('/livecall')
 

  };


  const agentstatus = (type) => {
    const input = {
      event_type: "accept_agent",
      email_id: userName,
      calltype: type,
    };
    dispatch(AcceptCall(input))
      .unwrap()
      .then(({ data }) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };





  return (
    <>
      <div className="mx-auto ">
        <div className="d-flex justify-content-center">
         
        </div>
        <div className="d-flex justify-content-center call-modal-user">

          <div className="customer-info mt-4">
          
            <Row>
              <Col className="pca-df text-white">
                <div className="pt-2 d-flex">
                  <div style={{ flex: '0 0 111px' }}>
                    <b>Customer Name</b>
                  </div>
                  <div>
                    :{CustomerUserName}
                  </div>
                </div>

                <div className="pt-2 d-flex">
                  <div style={{ flex: '0 0 111px' }}>
                    <b>Phone Number</b>
                  </div>
                  <div>
                    :{CustomerMobileNumber}
                  </div>
                </div>

                <div className="pt-2 d-flex">
                  <div style={{ flex: '0 0 111px' }}>
                    <b>Email ID</b>
                  </div>
                  <div>
                    :{CustomerEmailID}
                  </div>
                </div>
              </Col>

            
            </Row>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <div className="px-4 ">
            <div className="d-flex justify-content-center">
              <Button
                shape="circle"
                size="large"
                className="phn-icon-call"
                onClick={handlecallpick}
              >
                <Icon icon="ic:sharp-call" className="phn-icon" />
              </Button>
            </div>
            <div className="call-modal-call">Open Call</div>
          </div>
          <div className="px-4">
            <div className="d-flex justify-content-center">
              <Button
                shape="circle"
                size="large"
                className="phn-icon-miss"
                onClick={props.handleCancel}
              >
                <Icon
                  icon="material-symbols:phone-missed"
                  className="phn-icon"
                />
              </Button>
            </div>
            <div className="call-modal-call">Close Call</div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}
export default DashboardCall;
